<script setup lang="ts">
import TrustableCarousel from '~/components/content/TrustableCarousel.vue'
import BeforeFooter from '~/components/content/BeforeFooter.vue'

const {
  locale,
  t,
} = useI18n()

const route = useRoute()

const days = computed(() => {
  const {
    0: monday,
    4: friday,
  } = getWeekdays(locale.value, 'long')
  return `${monday} - ${friday}`
})
const hours = computed(() => {
  const {
    6: six,
    18: eighteen,
  } = getHours(locale.value)
  return `${six} - ${eighteen}`
})
const isCheckoutPage = computed(() => ((route?.name as string) ?? '').includes('checkout'))
</script>

<template>
  <div class="n-top-bar font-m relative z50 block hidden bg-main-950 py1 text-white lg:block">
    <div class="mx-auto h-7 max-w-[83rem] flex items-center justify-between">
      <div class="flex items-center gap-x-2">
        <span class="h-10 min-w-sm w-auto w-fit font-medium" n="sm">
          <TrustableCarousel
            child-class="min-w-0  text-white fw600 uppercase"
            wrapper-class="absolute inset-0 flex items-center px2 overflow-hidden"
          />
        </span>
        <span class="hidden-xs text-xs text-white md:block">
          <NuxtLinkLocale to="/pages/doba-dodani">
            Obvyklá doba dodání je 5-30 pracovních dní
          </NuxtLinkLocale>
        </span>
      </div>

      <span class="inline-flex items-center justify-center font-medium uppercase" n="sm" />

      <span class="inline-flex items-center justify-center font-medium" n="sm">
        <div class="flex items-center">
          <div class="flex gap-1">
            <NuxtImg src="/team/1.jpg" alt="Team 1" class="h-4 w-4 rounded-full" />
            <NuxtImg src="/team/2.jpg" alt="Team 2" class="h-4 w-4 rounded-full" />
            <NuxtImg src="/team/3.jpg" alt="Team 3" class="h-4 w-4 rounded-full" />
            <div>
              <p class="text-xs capitalize">
                {{ $t('trustable.contact_agent') }} | {{ days }}  {{ hours }}</p>
            </div>
            <div class="flex items-center text-sm">
              <NIcon icon="ph:phone mr-1" />
              <a class="text-xs">| {{ $config.public.company.phoneNumber }}</a>
            </div>
          </div>
        </div>
      </span>
    </div>
  </div>
  <div class="h10 bg-black lg:hidden">
    <TrustableCarousel
      child-class="min-w-0 flex items-center justify-center text-white fw600 uppercase"
      wrapper-class="absolute inset-0 flex items-center justify-center"
    />
  </div>

  <LayoutHeader />

  <slot />

  <LazyNavDrawer />
  <LazyCartDrawer />

  <div class="flex-1" />

  <ContentGuard>
    <div v-if="!isCheckoutPage" class="mb6 bg-slate-50">
      <div class="mx-auto max-w-[88rem] py8 lg:px4 sm:px2 sm:py14">
        <BeforeFooter />
      </div>
    </div>
  </ContentGuard>
  <!-- Footer -->
  <LayoutFooter />
</template>
